import BlogTitle from "../../../../src/posts/post-components/BlogTitle";
import BlogColumn from "../../../../src/posts/post-components/BlogColumn";
import ColumnHeading from "../../../../src/posts/post-components/ColumnHeading";
import ColumnText from "../../../../src/posts/post-components/ColumnText";
import Image from "../../../../src/posts/post-components/Image";
import * as React from 'react';
export default {
  BlogTitle,
  BlogColumn,
  ColumnHeading,
  ColumnText,
  Image,
  React
};